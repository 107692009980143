import { Chain, ChainResponse } from "@app/domains/chain/models";
import { NotFound } from "@app/domains/shared/error-pages";
import { SsrLogger } from "@app/domains/shared/ssr-utils";
import { AxiosError } from "axios";
import { GetServerSideProps } from "next";
import ChainPage from "./[chain]";

const IndexPage: React.VFC<ServerProps> = ({ chainResponse }) => {
  return chainResponse ? (
    <ChainPage chainResponse={chainResponse} />
  ) : (
    <NotFound />
  );
};

type ServerProps = {
  chainResponse?: ChainResponse;
  serverError?: string;
};

export const getServerSideProps: GetServerSideProps<ServerProps> = async (
  context,
) => {
  const { req, res } = context;
  const logger = new SsrLogger();
  const origin = req.headers["x-ifood-forwarded-host"] as string;

  try {
    if (origin) {
      const chainResponse = await Chain.getChainByHost(origin);

      if (chainResponse.redirectMerchant) {
        res.setHeader(
          "Location",
          `/${chainResponse.slug}/${chainResponse.redirectMerchant}`,
        );
        res.statusCode = 302;
        res.end();
      }

      return { props: { chainResponse } };
    } else {
      return { props: {}, notFound: true };
    }
  } catch (e) {
    const error = e as AxiosError;
    logger.warn({ error });

    if (error.response?.status === 404) {
      logger.info({ message: error.message });
      return { props: {}, notFound: true };
    }

    return {
      props: { serverError: JSON.stringify(error) },
    };
  }
};

export default IndexPage;
